<style lang="scss" scoped>
@import "../styles/section";

.footer {
  .section-header {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(#000000, 0.3);
      z-index: 1;
    }

    background-image: url("https://ik.imagekit.io/kreatikart/patrol_paris/patrol-team-min.jpg");
    background-position: top;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .header-text {
      color: white;
      font-family: "adobe-handwriting-ernie", serif;
      font-size: 1.75rem;
      line-height: 2.75rem;
      text-align: center;
      padding-bottom: 2rem;
      z-index: 2;
      @media screen and (min-device-width: 768px) {
        font-size: calc(1.75rem + 0.5rem);
        line-height: calc(2.75rem + 0.5rem);
      }

      span {
        color: $primary;
      }
    }
  }

  .section-content {
    .grid-container {
      display: grid;
      min-width: 70%;
      grid-template-columns: repeat(3, 1fr);
      //@media screen and (max-height: 768px) {
      //  grid-template-columns: 1fr;
      //}
      //grid-template-rows: repeat(2, auto);
      align-items: stretch;
      align-content: flex-start;
      justify-items: stretch;
      grid-gap: 1rem;
      grid-template-areas:
        "infos infos infos"
        "services sitemap sitemap"
        "follow sitemap sitemap";
      @media screen and (min-device-width: 768px) {
        grid-gap: 2rem;
        grid-template-areas:
          "infos services follow"
          "infos services sitemap";
      }

      h3 {
        border-bottom: solid 1px rgba(#ffffff, 0.3);
        text-transform: uppercase;
        font-size: 1.25em;
        text-align: center;
        margin-bottom: 0.75rem;
        font-family: "cheap-pine-sans", sans-serif;
        padding: 0.25rem 0;
      }

      .footer-infos {
        grid-area: infos;
        display: grid;
        grid-gap: 1rem;
        align-content: space-evenly;
        grid-template-areas:
          "block block"
          "link-book link-contact";
        @media screen and (min-device-width: 768px) {
          grid-template-areas:
            "block"
            "link-book"
            "link-contact";
        }
        .infos-link-booking {
          grid-area: link-book;
        }

        .infos-link-contact {
          grid-area: link-contact;
        }

        .infos-block {
          grid-area: block;
          display: grid;
          grid-gap: 8px;
          justify-items: center;

          .infos-hours {
            text-transform: uppercase;
            font-size: 1.25rem;
            font-family: "cheap-pine-sans", sans-serif;
          }

          .infos-phone {
            color: inherit;
            text-decoration: none;
            transition: 150ms;
            &:hover,
            &:active {
              color: $primary;
            }
          }

          &:before,
          &:after {
            content: "";
            height: 4px;
            width: 100%;
            border-color: white;
            border-left: solid 1px;
            border-right: solid 1px;
          }

          &:before {
            border-top: solid 1px;
          }

          &:after {
            border-bottom: solid 1px;
          }
        }
      }

      .footer-sitemap {
        grid-area: sitemap;
        text-align: center;
        .sitemap-list {
          font-size: 1rem;
          column-count: 2;
          @media screen and (min-device-width: 768px) {
            font-size: 1em;
            column-count: 2;
          }

          li {
            line-height: 1.5em;
            a {
              color: inherit;
              text-decoration: none;
              transition: 150ms;
              font-size: 1em;
              &:hover {
                color: $primary;
              }
            }
          }
        }
      }

      .footer-follow {
        grid-area: follow;
        justify-self: center;

        .follow-list {
          li a {
            color: inherit;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: 150ms;
            text-decoration: none;
            &:hover {
              color: $primary;
            }

            svg {
              padding-right: 0.25rem;
            }
          }
        }
      }

      .footer-services {
        grid-area: services;
        .services-grid {
          display: grid;
          grid-auto-flow: column;
          @media screen and (min-device-width: 768px) {
            grid-template-columns: 24px minmax(24px, auto);
            grid-auto-flow: row;
          }
          justify-content: stretch;
          justify-items: stretch;
          align-items: center;
          grid-gap: 0.75rem;
          span {
            text-transform: uppercase;
            font-size: 0.75em;
            display: none;
            @media screen and (min-device-width: 768px) {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <footer class="section footer">
    <div class="section-header">
      <transition
        @before-enter="elasticBeforeEnter"
        @enter="elasticEnter"
        @leave="elasticLeave"
      >
        <p class="header-text" v-show="$store.getters['swiper/getIndex'] === 5">
          " You can check out<br />
          anytime you like,<br />
          but you can <span>never leave</span> ! "
        </p>
      </transition>
    </div>
    <transition
      @before-enter="staggerBeforeEnter"
      @enter="staggerEnter"
      @leave="staggerLeave"
    >
      <div
        class="section-content"
        v-show="$store.getters['swiper/getIndex'] === 5"
      >
        <div class="container grid-container">
          <div class="footer-infos" data-stagger>
            <div class="infos-block">
              <span class="infos-hours">
                Ouvert <strong>tous les jours</strong> de <strong>8h</strong> à
                <strong>1h</strong>
              </span>
              <span class="infos-address"
                >2 place Edmond Rostand 75006 Paris</span
              >
              <a href="tel:+33153198270" class="infos-phone">
                <icon-phone />
                +33 (0)1 53 19 82 70
              </a>
            </div>
            <router-link
              :to="{ name: 'Booking' }"
              class="base-btn primary infos-link-booking"
              >Réserver en ligne
            </router-link>
            <router-link
              :to="{ name: 'ContactUs' }"
              class="base-btn primary infos-link-contact"
              >Nous contacter
            </router-link>
          </div>
          <div class="footer-services" data-stagger>
            <h3>Services</h3>
            <div class="services-grid">
              <icon-wheelchair />
              <span>entrée accessible aux fauteuils roulants</span>
              <icon-veggie />
              <span>plats végétariens et sans gluten</span>
              <icon-kids />
              <span>kids friendly</span>
              <icon-wifi />
              <span>wifi gratuit</span>
              <icon-bag />
              <span>à emporter</span>
              <icon-card />
              <span>cartes bancaires acceptées</span>
            </div>
          </div>
          <div class="footer-follow" data-stagger>
            <h3>stay tuned</h3>
            <ul class="follow-list">
              <li>
                <a
                  href="https://www.instagram.com/patrol_paris"
                  target="_blank"
                  rel="noopener"
                >
                  <icon-instagram size="1rem" />
                  instagram.com/patrol_paris
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/patrolparis"
                  target="_blank"
                  rel="noopener"
                >
                  <icon-facebook size="1rem" />
                  facebook.com/patrolparis
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-sitemap" data-stagger>
            <h3>plan du site</h3>
            <ul class="sitemap-list">
              <li><a href="/landing">Patrol</a></li>
              <li><a href="/food">Food</a></li>
              <li><a href="/drink">Drinks</a></li>
              <li><a href="/place">Place</a></li>
              <li>
                <router-link :to="{ name: 'ContactUs' }">Contact</router-link>
              </li>
              <li>
                <a
                  href="https://deliveroo.fr/fr/menu/paris/6eme-odeon/patrol"
                  target="_blank"
                  rel="noopener"
                  >Delivery</a
                >
              </li>
              <li>
                <router-link :to="{ name: 'Booking' }">Booking</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'LegalNotice' }"
                  >Mentions</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
    <div class="section-footer">
      patrol-paris.com | 2020 | Kreatik Art
    </div>
  </footer>
</template>

<script>
import { stagger, elastic } from "@/js/gsap-transitions-hooks";

export default {
  name: "Footer",
  methods: {
    ...stagger,
    ...elastic
  }
};
</script>
